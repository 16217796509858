<template>
  <div id="homeVue">
    <div class="page-header-home">
      <div class=" container ">
        <div class="main-div">
          <div class="container">
            <div class="mt-8">
              <validation-observer ref="observer">
                <form
                  id="home_search_form"
                  @submit.prevent="validateAndSearch()"
                  action=""
                >
                  <div class="searchBox">
                    <div class="homemultiselect">
                      <div class="search-for ">
                        <validation-provider
                          name="This field"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <base-input
                            v-model="f_query"
                            placeholder="What company  or person  are you looking for?"
                            :error="errors[0]"
                          >
                          </base-input>
                        </validation-provider>
                      </div>
                      <div class="search-country " hidden>
                        <multi-select
                          multiple
                          label="country_name"
                          track-by="record_number"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :options="country_list"
                          v-model="f_country_list"
                          placeholder="Select country"
                        >
                          <template slot="option" slot-scope="props">
                            <div class="option__desc">
                              <span class="option__title">{{
                                props.option.country_name
                              }}</span>
                              <br />
                              <small>
                                <span class="option_small">{{
                                  props.option.record_type
                                }}</span>
                                <span v-if="props.option.results_count">
                                  ({{ props.option.results_count }})
                                </span>
                              </small>
                            </div>
                          </template>
                          <template slot="tag" slot-scope="props">
                            <span class="multiselect__tag">
                              <span>{{ props.option.country_name }}</span>
                              <br />
                              <small>
                                <span>
                                  {{ props.option.record_type }}
                                </span>
                                <!-- <span v-if="props.option.results_count != null">
                            ({{ props.option.results_count }})
                          </span> -->
                              </small>
                              <i
                                aria-hidden="true"
                                tabindex="1"
                                @keydown.enter.prevent="
                                  props.remove(props.option)
                                "
                                @mousedown.prevent="props.remove(props.option)"
                                class="multiselect__tag-icon"
                              ></i>
                            </span>
                          </template>
                        </multi-select>
                      </div>
                      <div class="serch-country-btn">
                        <button
                          class="btn btn-info btn-block"
                          id="btn-search"
                          @click="validateAndSearch()"
                          type="info"
                          size="lg"
                        >
                          <span class="fa fa-search"></span>SherlockIT
                        </button>
                      </div>
                    </div>
                    <div class="infobutton" @click="modal_status = true">
                      <span id="clickhere">
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgEAYAAAAj6qa3AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5QgNAhUagRR4jQAABJ5JREFUaN7tmVtMFGcUx/9nlh3cBRsCtluLBZXCoEA0YECURtNWUIqXVB5qrDw0SiXuukIVSkispRgrBotxuUg1JtpqLEmDNCUu1+oDEW9JwS23iForLiW0KV5QZndOHwgvGtNZyy6s6f/5/M+c75fvfLchTLJ0BVvf7wkMDnZafdZyWHExZ2Etji1bRn14gzJ0Oo7B73z84kXYnTYszc+Xd1S8Jz1qb5+o79NkDVwsNZd3HZUkDDpNgqW1FQeQxWcCA59rCOd9CB4dpXyeBltq6pOPyrVSQlPTf61DmCwAvNkZQDV79/7rwMfVS/m4K4psJKYjpaUTVcekAcBPMGBBfLzLvkeUw3nR0UBmZv8Wvd5rAdBeGBAzNOSyMQ2vUeKDB8DMIzOrHj/2WgBKLedwelWVqz7OgAWwWIAviEhRvBaAY3ZZvaSprIQIE7r37Blf5J4JFGFCNzN2YS7ePX5cXje6dti6e/dE1TFpu8DT8mUzd/Ls2cp5ZY3mm8RE5CNLidfp0KB86pPb1ib7l3W+VW+zTXad/+tlkwdb4HNmFgT9239/26U3GBw/KAGc4++vjaeqaaHDww9vHvpxbubAwEsAwFTXa/T1FT9QmvlUaiqfoFhesnEjHUQHNSxfjiLc5/agoKddfBmX0XLjBmuQiwsHDzqiLesjvquoAAAi5ikPQGzc1tbdnpODFlpCGQUFqk94zxHnci5aSkrkorIQaevOne4CMGHbIE+jowg1GrEPEm+w27mQC/FJfT0/hBl9nZ2u5qNy+hj3s7N915nqeo1hYVMYwFhv+8zAAFcnJY0qll+lvKgo+bOyQOnnlBRZawmXVs2fjwuYAamyUnXaB4jEGkFQgpQg7k5KmsIAxk5kI5GW2sjN/f3Pi+JoPoO0lhaX0zeggddrtVMYgEqtEEq4yN/fVRt9JcQK1923O3gOwGq+T4Xz5rkMYIOwynG4o8PrAZAdmXhVfS9zMRfTmnv3ntAhisTt214MwMw3OSAANZhOvYsWqXXRLQxwnNUKuPcc4HYAotZpfNK1YgXsWMcVPj5qfZxDwOZz59xdn/tnwB6OFU6vXKk6PhDJOOB0yiH8h2JrbPRqAMxE/DqlcXpKimpTBuz45dIloCxhnukFXoymCgBtaVZjj19MDGXhS/gGB6s2nuAZWOD+qe92APSKJpN2JCe76lOi2YFFzc1eD4Az+DQMCQmqDSEIoXdk2dHoO0v/8OpVTwFQvSq7ToACOSIqSnX4KXzI39+5g/ivQ95cOjLiKQDuWwT3IwWuXIdn4RTlOxyeGrj7AWxEP/kND6sNp1jM54iwsLHr77Nrh98c8+q+KoNBq92+vbMzLm7qAzjGPRxTW6s6/k/UY5dGw3Vcp5isVrHAqOlZODQk7jf+1d03OCjfdYbKy+x27nV2UfgL/FHyNAC5SLguXC8sxGIshvnaNZcTjL8oNaABeXo90ugYJZ48KW7SbuLpNTUTVacHHkWzW++U6HRipiw+8jObcYtD6Xx6OvypAlZJQjdn82+iyFugobihISzEPdZcuYJt1AS/s2dlG80SEqqrgcOp4Rb1LaVW/wActc2hn3nmygAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wOC0xM1QwMjoyMToyNiswMDowMEp8NfUAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDgtMTNUMDI6MjE6MjYrMDA6MDA7IY1JAAAAAElFTkSuQmCC"
                          alt="Sherlock IT"
                        />
                      </span>
                      <!---->
                    </div>
                  </div>
                  <input type="submit" style="display: none" />
                </form>
              </validation-observer>
              <div>
                <country-badge
                  :country_list="country_list"
                  :selected="f_country_list"
                ></country-badge>
              </div>
            </div>
          </div>
        </div>
        <div class="align-items-center text-left main-div2">
          <div class="container">
            <div class="logo-pic">
              <img src="@/assets/img/home/logo.png" />
              <p>
                Welcome to SherlockIT worldwide corporate data at your
                fingertips.<br />
                SherlockIT is the go-to place when you are looking for worldwide
                Company filings, office bearers and individuals across multiple
                jurisdictions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="alerts">
        <base-alert type="danger" v-if="errors.no_search_remaining" dismissible>
          <span class="alert-inner--icon"><i class="fa fa-warning"></i></span>
          <span class="alert-inner--text"
            >You have reached the allocated number of search queries. Please
            contact sherlockit support via Contact Us or email us on
            support@sherlockit.net</span
          >
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </base-alert>
        <base-alert
          type="danger"
          v-if="errors.internal_server_error"
          dismissible
        >
          Oops! Something went wrong in the server.</base-alert
        >
      </div>
      <div v-if="search_result" class="mb-5" id="search-result">
        <div v-if="search_result.status == 1">
          <base-alert type="danger" dismissible>
            <span class="alert-inner--icon"><i class="fa fa-warning"></i></span>
            <span class="alert-inner--text">{{ search_result.reason }}</span>
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </base-alert>
        </div>
        <h1 class="display-4 text-primary">Search Results</h1>
        <p class="text-default" style="font-size: 16px">
          Found <strong>{{ search_result.matches }}</strong> entries matching
          your search criteria. (<strong>{{ search_result.time }}</strong>
          milliseconds)
          <br />
          Display is limited to top <strong>200</strong> records.
          <br />
          <span v-if="search_result.search_allocation"
            >Number of queries remaining:
            <strong>{{
              search_result.search_allocation.remaining
            }}</strong></span
          >
        </p>
        <div v-if="get_search_results.length != 0">
          <div
            v-for="(item, index) in get_search_results"
            :key="index"
            class="result-list-item mb-3"
          >
            <div>
              <h3>
                {{ page_size * (page_number - 1) + (index + 1) }}.
                <router-link :to="showDetail(item, true)"
                  >{{ item["corporate_name"] }},
                  {{ item["country"] }}</router-link
                >
              </h3>
            </div>
            <div v-html="item.excerpt" class="excerpt"></div>
            <div class="mt-1">
              <base-button
                v-if="
                  !history[index].data &&
                    item.has_history &&
                    !history[index].error &&
                    !history[index].loading
                "
                class="btn-round"
                type="info"
                size="sm"
                @click="showHistory(item, index)"
                ><i class="fa fa-plus"></i>
              </base-button>
              <span
                v-else-if="history[index].error"
                class="text-danger ml-3 mr-3"
                >Could not get historical record <i class="fa fa-warning"></i
              ></span>
              <spinner
                v-if="history[index].loading"
                size="sm"
                class="ml-2 mr-2"
              />
              <span v-if="!history[index].data && item.has_history" class="mr-2"
                >|</span
              >
              <span @click="showDetail(item)" class="btn-result btn-more-detail"
                >More Detail <i class="fa fa-info-circle"></i
              ></span>
              |
              <span @click="saveResult(item)" class="btn-result btn-save-result"
                >Save <i class="fa fa-save"></i
              ></span>
            </div>
            <div class="container history-group" v-if="history[index].data">
              <hr style="width: 100%" />
              <div class="row">
                <div class="col col-sm-10 historical-record">
                  Historical Record <i class="fa fa-history"></i>
                </div>
                <div class="col col-sm-2 text-right">
                  <span
                    class="cursor-pointer"
                    @click="history[index].data = null"
                    ><i class="fa fa-minus"></i
                  ></span>
                </div>
              </div>
              <div
                class="history"
                v-for="(h, hindex) in history[index].data"
                :key="hindex"
              >
                <strong
                  ><span
                    class="history-detail-link text-primary"
                    @click="showHistoryDetail(h)"
                    >{{ h.corporate_name }}</span
                  ></strong
                >
                <br />
                <div>
                  Updated
                  <span
                    class="history-last-updated"
                    v-b-tooltip.hover.bottom
                    :title="formatted_time(h.last_updated)"
                    ><strong>{{ from_now(h.last_updated) }}</strong></span
                  >
                </div>
                <div class="mt-2">
                  <span
                    @click="showHistoryDetail(h)"
                    class="btn-result btn-more-detail"
                    >More Detail <i class="fa fa-info-circle"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-8">
              <base-pagination
                class="justify-content-center"
                :page-count="page_count"
                v-model="page_number"
                iconLeft="ni ni-bold-left"
                iconRight="ni ni-bold-right"
                type="info"
              ></base-pagination>
            </div>
            <div class="col-sm-2 text-right">
              <base-button
                type="primary"
                rounded
                iconOnly
                size="lg"
                @click="scrollTop()"
                ><span class="fa fa-arrow-up"></span
              ></base-button>
            </div>
          </div>
        </div>
        <div v-if="!$store.getters.auth && search_result" class="mt-3">
          If you already have an account
          <span
            style="cursor: pointer"
            class="text-danger"
            @click="redirectToLogin()"
            >Log In here
          </span>
        </div>
      </div>
      <div v-if="loading" class="text-center mt-4 mb-4">
        <spinner type="grow" size="lg" />
      </div>
      <div class="col-lg-12 col-12 pl-0 pr-0 search-image">
        <img src="@/assets/img/home/home-banner.43ab5354.webp" />
      </div>
      <div>
        <Feature3 />
      </div>
      <div>
        <Feature4 />
      </div>
      <div>
        <Feature5 />
      </div>
      <div>
        <Feature6 />
      </div>
      <div>
        <Feature7 />
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="section text-center">
          <div class="free_try">
            <h3>
              REGISTER YOUR ACCOUNT IN LESS THAN A MINUTE AND TRY THE PORTAL FOR
              FREE
            </h3>
            <router-link to="/search">
              Try For Free !
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="bg_img">
      <div class="section text-center ">
        <div class="row">
          <div
            v-for="(each, index) in web_stats"
            :key="'stats' + index"
            class="width_20_coumty col-md col-sm-12"
          >
            <div>
              <h3 class="number_headings">
                <div>
                  <number
                    ref="number"
                    :from="0"
                    :to="
                      each[Object.keys(each)[0]].replace(/[^\d\.\-eE+]/g, '')
                    "
                    :format="theFormat"
                    :duration="2"
                    easing="Power1.easeOut"
                    animationPaused
                  />
                </div>
              </h3>
            </div>
            <span class="content_bottom"> {{ Object.keys(each)[0] }}</span>
          </div>
        </div>
      </div>
    </div>

    <base-button
      type="primary"
      class="floatingBtn"
      v-if="showScrollTop"
      rounded
      iconOnly
      size="lg"
      @click="scrollTop()"
      ><span class="fa fa-arrow-up"></span
    ></base-button>
    <modal :show.sync="modal_status">
      <div class="modal-backdrop" @click="modal_status = false">
        <div class="card-body">
          <button type="button" @click="modal_status = false" class="btn-close">
            &times;
          </button>
          <section class="modal-body">
            <div class="searchtips">
              <div class="srch_tips_div">
                <div class="top_img_serch text-center">
                  <img src="@/assets/img/home/lightbulb.png" />
                </div>
                <div class="heading_search text-center">
                  <h1><b>Tips</b> for search</h1>
                </div>
                <div class="infosech">
                  <ul class="card-bodyinner">
                    <li>
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        Search for any phrase or combination of words:
                        <em> apple banana</em> (highest ranking for records with
                        exact phrase)
                      </p>
                    </li>
                    <li>
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        Search for records with accented characters: João,
                        résumé
                      </p>
                    </li>
                    <br />
                    <li class="fullwidth">
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        Wild card (*) search (minimum of 3 characters required)
                      </p>
                    </li>
                    <li>
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        <em>APP*</em> search will match records that contains
                        any words starting with <em>APP</em>: Apple, App,
                        Appleby (including the word <em>APP</em>)
                      </p>
                    </li>
                    <li>
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        <em>*APP*</em> search will match records that contain
                        any words which have <em>APP</em> in any part of the
                        word (including the word <em>APP</em>)
                      </p>
                    </li>
                    <li>
                      <span
                        ><i aria-hidden="true" class="fa fa-circle"></i
                      ></span>
                      <p>
                        <em>*APP</em> search will match records that contain any
                        words ending <em>APP</em> : Rapp, App (including the
                        word <em>APP</em>)
                      </p>
                    </li>
                  </ul>
                  <div class="gotit">
                    <a @click="modal_status = false" href="javascript:void(0)"
                      >GOT IT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
const MultiSelect = () =>
  import(/* webpackChunkName: "MultiSelect"*/ "vue-multiselect");
const Feature3 = () =>
  import(/* webpackChunkName: "Feature3"*/ "../components/Feature3");
// import Feature3 from "../components/Feature3";
const Feature4 = () =>
  import(/* webpackChunkName: "Feature4"*/ "../components/Feature4");
// import Feature4 from "../components/Feature4";
const Feature5 = () =>
  import(/* webpackChunkName: "Feature5"*/ "../components/Feature5");
// import Feature5 from "../components/Feature5";
const Feature6 = () =>
  import(/* webpackChunkName: "Feature6"*/ "../components/Feature6");
// import Feature6 from "../components/Feature6";
const Feature7 = () =>
  import(/* webpackChunkName: "Feature7"*/ "../components/Feature7");
// import Feature7 from "../components/Feature7";
////search componet
import Modal from "@/components/Modal.vue";
import Moment from "moment";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import Spinner from "@/components/Spinner";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CountryBadge from "@/components/RecordCountBadge";
export default {
  data: function() {
    return {
      search_data: {
        query: "",
        country_list: []
      },
      modal_status: false,
      country_list: [],
      search_result: null,
      page_number: 1,
      page_size: 20,
      loading: false,
      web_stats: [],
      errors: {
        no_search_remaining: false,
        internal_server_error: false
      },
      cl_url: [], //country_list from url
      meta: {
        title: "Search | SherlockIT"
      },
      results: [],
      history: [],
      animation_started: false,
      showScrollTop: false
    };
  },
  directives: {
    BTooltip: VBTooltip
  },
  mounted() {
    this.processURLData();
    this.updateCountryList(true);
    this.updateTitle();
    this.get_web_stats();
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    f_query: {
      get() {
        return this.search_data.query;
      },
      set(data) {
        this.search_data.query = data;
      }
    },
    f_country_list: {
      get() {
        return this.search_data.country_list;
      },
      set(data) {
        this.search_data.country_list = data;
      }
    },
    page_count() {
      if (!this.search_result) {
        return 1;
      }
      return Math.ceil(this.search_result.results.length / this.page_size);
    },
    get_search_results() {
      let from = this.page_size * (this.page_number - 1);
      let to = from + this.page_size;
      if (this.search_result && this.search_result.results){
        return this.search_result.results.slice(from, to);
      } else {
        return "";
      }
    },
    validated_search_data() {
      let country_list = [];
      this.search_data.country_list.forEach(e => {
        country_list.push(parseInt(e.record_number));
      });
      return {
        query: this.search_data.query,
        country_list: country_list
      };
    }
  },
  methods: {
    handleScroll() {
      const { scrollHeight } = document.getElementById("app");
      this.showScrollTop = scrollY >= window.innerHeight / 2;
      let percent = (scrollY * 100) / scrollHeight;
      if (!this.animation_started && percent >= 70 && percent < 85) {
        this.$refs.number.forEach(item => item.play());
        this.animation_started = true;
      }
    },
    theFormat(number) {
      number = parseInt(number);
      return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    processURLData() {
      //check for query in url
      if (this.$route.query.q) {
        this.f_query = this.$route.query.q;
      } else {
        this.f_query = "";
        this.search_result = null;
      }
      //check for country list in url
      if (this.$route.query.c) {
        var country_list = this.$route.query.c.split(",");
        for (var i in country_list) {
          if (!parseInt(country_list[i])) {
            country_list.splice(i, 1);
          }
        }
        this.cl_url = country_list;
      }
    },
    processURLCountry() {
      if (this.cl_url) {
        for (var i in this.cl_url) {
          let c = this.getCountry(this.cl_url[i]);
          if (c) {
            this.cl_url[i] = c;
          } else {
            this.cl_url.splice(i, 1);
          }
        }
        this.f_country_list = this.cl_url;
      }
    },
    search_data_for_url(type) {
      let data = {};
      Object.assign(data, this.validated_search_data);
      data.country_list = data.country_list.join(",");
      if (type == "obj") {
        return {
          q: data.query,
          c: data.country_list
        };
      } else {
        return `q=${data.query}&c=${data.country_list}`;
      }
    },
    get_web_stats() {
      this.$axios.get("search/web-stats/").then(res => {
        this.web_stats = res.data;
      });
    },
    search() {
      this.loading = true;
      this.search_result = null;
      this.removeErrors();
      this.$axios
        .post("search/", this.validated_search_data)
        .then(res => {
          if (res.data.country_list) {
            if (res.data.country_list.length != 0) {
              this.country_list.forEach(e => {
                let c_res = res.data.country_list.find(
                  c => c.record_number == e.record_number
                );
                if (c_res) {
                  e.results_count = c_res.results_count;
                } else {
                  e.results_count = 0;
                }
              });
            }
          }
          this.search_result = res.data;
          this.history = [];
          for (var i = 0; i < this.search_result.results.length; i++) {
            this.history.push({
              loading: false,
              data: null,
              error: false
            });
          }
          this.loading = false;
          this.page_number = 1;

          this.$store.commit("search_url", this.$route.fullPath);
        })
        .catch(err => {
          this.loading = false;
          let err_res = err.response.data;
          if (err_res.error == "no_search_remaining") {
            this.errors.no_search_remaining = true;
          }
          if (err.response.status == 500) {
            this.errors.internal_server_error = true;
          }
        });
    },
    validateAndSearch() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.updateURL();
        this.updateTitle();
      });
    },
    getCountry(record_number) {
      for (var i in this.country_list) {
        if (this.country_list[i].record_number == record_number) {
          return this.country_list[i];
        }
      }
      return null;
    },
    sortCountryList() {
      this.country_list.sort((a, b) =>
        (a["country_name"] || "")
          .toString()
          .localeCompare((b["country_name"] || "").toString())
      );
    },
    updateCountryList(mounted) {
      this.$axios.get("search/country-list/").then(res => {
        // this.country_list.push(...res.data);
        this.country_list = res.data;
        this.sortCountryList();
        this.processURLCountry();
        if (mounted && this.f_query) {
          this.search();
        }
      });
    },
    reset() {
      this.f_query = "";
      this.f_country_list = [];
      this.$refs.observer.reset();
      this.search_result = null;
      this.meta.title = "Search | SherlockIT";
      this.updateCountryList();
      if (this.$router.path != "/search") {
        this.$router.push("/search");
      }
    },
    showDetail(data, linkOnly) {
      if (linkOnly) {
        return `/search/detail/${data.urn}-${data.country_code}`;
      }
      this.$router.push(`/search/detail/${data.urn}-${data.country_code}`);
    },
    removeErrors() {
      for (var e in this.errors) {
        this.errors[e] = false;
      }
    },
    saveResult(data) {
      this.$axios
        .post("search/save/", {
          country_code: data.country_code,
          urn: data.urn,
          query: this.f_query
        })
        .then(res => {
          if (res.status == 200) {
            this.$toasted.show(`Record saved successfully`, {
              type: "success",
              position: "top-right",
              duration: 5000,
              action: [
                {
                  text: "x",
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  }
                }
              ]
            });
          }
        })
        .catch(err => {
          if (err.response) {
            this.$toasted.show(`Could not save record`, {
              type: "error",
              position: "top-right",
              duration: 5000,
              action: [
                {
                  text: "x",
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  }
                }
              ]
            });
          }
        });
    },
    scrollTop() {
      document.querySelector("#homeVue").scrollIntoView({
        behavior: "smooth"
      });
    },
    redirectToLogin() {
      if (!this.$store.getters.auth) {
        this.$store.commit(
          "after_login",
          `/search?${this.search_data_for_url()}`
        );
        this.$router.push("/login");
      }
    },
    updateTitle() {
      if (this.f_query) {
        this.meta.title = `${this.f_query} - SherlockIT`;
      } else {
        this.meta.title = "Search | SherlockIT";
      }
    },
    updateURL() {
      let data = this.search_data_for_url("obj");
      if (this.$route.query.q != data.q || this.$route.query.c != data.c) {
        this.$router.push(`/search?${this.search_data_for_url()}`);
      } else {
        this.search();
      }
    },
    showHistory(item, index) {
      this.history[index].loading = true;
      this.$axios
        .get(`search/history/${item.urn}-${item.country_code}`)
        .then(res => {
          this.history[index].data = res.data;
          this.history[index].data = res.data.reverse();
          this.history[index].loading = false;
        })
        .catch(err => {
          if (err.response) {
            this.history[index].error = true;
            this.history[index].loading = false;
          }
        });
    },
    formatted_time(d) {
      return Moment(d).format("lll");
    },
    from_now(d) {
      return Moment(d).fromNow();
    },
    showHistoryDetail(h) {
      this.$router.push(`/search/detail/${h.urn}-${h.country_code}`);
    }
  },
  metaInfo() {
    return {
      title: "SherlockIT",
      meta: [
        {
          name: "description",
          content:
            "SherlockIT Portugal Data Check: find information on legal case data, company filings, insolvencies and bankruptcies in Portugal"
        },
        {
          name: "keywords",
          content:
            "company filings, legal case data, insolvencies bankruptcies of people and companies in Portugal, tax number Portugal, insolvency"
        }
      ]
    };
  },
  components: {
    Modal,
    MultiSelect,
    Feature3,
    Feature4,
    Feature5,
    Feature6,
    Feature7,
    Spinner,
    ValidationProvider,
    ValidationObserver,
    CountryBadge
  },
  watch: {
    $route() {
      this.processURLData();
      this.updateTitle();
      this.processURLCountry();
      // this.updateCountryList();
      if (this.f_query) {
        this.search();
      }
    }
  }
};
</script>
<style scoped>
.sherlockit-input {
  border-color: #76aa8d;
  box-shadow: 0 0 0 0.1rem rgba(47, 116, 62, 0.25);
}

.sherlockit-input::placeholder {
  color: rgb(73, 94, 104);
}
</style>
