<template>
  <div v-if="get_data">
    <div class="text-center">
      <a
        v-for="(country, index) in displayed_country_badges"
        :key="index"
        @click="selectFilter(country, index)"
        class="btn-filter"
      >
        <badge
          v-if="country.results_count"
          class="ml-1 my-1 badge-lg bg-gradient-primary"
          rounded
          >{{ country.country_name }}
          {{
            country.results_count != null || country.results_count != undefined
              ? `(${country.results_count})`
              : ``
          }}</badge
        > </a
      ><br />
      <a class="pointer" v-if="show_more" @click="showMore()" type="icon"
        ><i class="fa fa-chevron-down"></i
      ></a>
      <a class="pointer" v-if="show_less" @click="l = default_l" type="icon"
        ><i class="fa fa-chevron-up"></i
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["country_list", "selected"],
  emits: ["update:onSelect"],
  data: () => ({
    default_l: 5,
    l: null,
    increment: 5,
    max: 200
  }),
  mounted() {
    this.l = this.default_l;
  },
  computed: {
    get_data() {
      var d = [];
      d.push(...this.country_list);
      if (d && d[0]) {
        if (d[0].results_count == null || d[0].results_count == undefined) {
          return null;
        }
      }
      d = this.sort(d, "country_name");
      if (this.l > this.max) {
        return d.slice(0, this.max);
      }
      return d.slice(0, this.l);
    },
    country_badges() {
      if (this.selected.length == 0) {
        return [];
      } else {
        let res = [];
        this.country_list.forEach(e => {
          if (this.isSelected(e)) {
            res.push(e);
          }
        });
        return res;
      }
    },
    displayed_country_badges() {
      let res = this.sort(this.country_badges, "country_name");
      if (this.l > this.max) {
        return res.slice(0, this.max);
      }
      return res.slice(0, this.l);
    },
    show_more() {
      if (this.l >= this.country_badges.length) {
        return false;
      }
      if (this.l >= this.max) {
        return false;
      }
      return true;
    },
    show_less() {
      if (this.l >= this.max && this.default_l != this.max) {
        return true;
      }
      if (
        this.l >= this.country_badges.length &&
        this.country_badges.length > this.default_l
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    sort(array, key) {
      return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        return x > y ? 1 : x < y ? -1 : 0;
      });
    },
    getType(country) {
      if (country) {
        return "info";
      }
    },
    showMore() {
      if (this.l < this.max) {
        this.l += this.increment;
      }
    },
    isSelected(item) {
      let res = false;
      this.selected.forEach(e => {
        if (e.record_number == item.record_number) {
          res = true;
        }
      });
      return res;
    },
    selectFilter(item) {
      this.$emit("update:onSelect", item);
    }
  }
};
</script>

<style scoped>
.pointer:hover {
  cursor: pointer;
}
</style>
